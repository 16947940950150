#ot-sdk-btn {
  background-color: #00965E;
  border-color: #00965E;
  color: #FFFFFF;
}
#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
  background-color: #00965E !important;
  border-color: #00965E !important;
  color: #FFFFFF !important;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left:20px;
  padding-right:20px;
  border-radius: 1000px;
}


