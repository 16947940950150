/* These styles make the body full-height */
html,
body {
  height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}

/* These styles make the root element full-height */
#root {
  display: flex;
  flex:1;
  height: 100%;
}


/*
 Cancel input focus outline
*/
input, input:focus, textarea, textarea:focus, *:focus {
  outline: none;
  outline-style: none;
}

/* 
  Cancel the eye in the inputs on Chromium and edge
*/

input::-ms-reveal,
input::-ms-clear {
  display: none;
}