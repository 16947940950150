@font-face {
  font-family: 'BNPPSans-Regular';
  src: url('../assets/fonts/BNPPSans/BNPPSans-Regular.eot');
  src: url('../assets/fonts/BNPPSans/BNPPSans-Regular.eot')
      format('embedded-opentype'), 
    url('../assets/fonts/BNPPSans/BNPPSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BNPPSans-Bold';
  src: url('../assets/fonts/BNPPSans/BNPPSans-Bold.eot');
  src: url('../assets/fonts/BNPPSans/BNPPSans-Bold.eot') format('embedded-opentype'),
    url('../assets/fonts/BNPPSans/BNPPSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BNPPSans-Light';
  src: url('../assets/fonts/BNPPSans/BNPPSans-Light.eot');
  src: url('../assets/fonts/BNPPSans/BNPPSans-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/BNPPSans/BNPPSans-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  src: url('../assets/fonts/fontIcons/icomoon.ttf');
  font-family: icomoon;
}
/* These styles make the body full-height */
html,
body {
  font-family: 'BNPPSans-Regular';
}